import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { createCheckoutSession } from "@invertase/firestore-stripe-payments";

function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [abn, setAbn] = useState('');
  const [address, setAddress] = useState('');
  const [existingCompany, setExistingCompany] = useState('');
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    const companiesCollection = collection(db, 'companies');
    const companiesSnapshot = await getDocs(companiesCollection);
    const companiesList = companiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCompanies(companiesList);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Create user

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      let companyId;

      if (existingCompany) {
        companyId = existingCompany;
      } else {
        // Create new company
        const newCompanyRef = doc(collection(db, 'companies'));
        await setDoc(newCompanyRef, {
          name: companyName,
          abn: abn,
          createdAt: new Date(),
          createdBy: user.uid,
          address: address,
          users: [user.uid]
        });
        companyId = newCompanyRef.id;
      }

      // Create user document
      await setDoc(doc(db, 'users', user.uid), {
        firstName,
        lastName,
        email,
        companyId,
        role: existingCompany ? 'user' : 'admin',
        address,
        status: existingCompany ? 'pending' : 'active'
      });

      /**
      const session = await createCheckoutSession(payments, {
        price: 'prod_R1JiflLt3MCpkE', 
        success_url: "https://oneventory.app/"
      });
      window.location.assign(session.url); **/

      navigate('/payment');
      
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      {error && <p className="error">{error}</p>}

      <form onSubmit={handleSignUp}>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="First Name"
          required
        />
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Last Name"
          required
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Address"
          required
        />
        <select
          value={existingCompany}
          onChange={(e) => setExistingCompany(e.target.value)}
        >
          <option value="">Create New Company</option>
          {companies.map(company => (
            <option key={company.id} value={company.id}>{company.name}</option>
          ))}
        </select>
        {!existingCompany && (
          <>
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Company Name"
              required
            />
            <input
              type="text"
              value={abn}
              onChange={(e) => setAbn(e.target.value)}
              placeholder="ABN"
              required
            />
          </>
        )}
        <button type="submit">Sign Up</button>
      </form>

    </div>
  );
}

export default SignUp;