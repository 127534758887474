import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, addDoc } from 'firebase/firestore';
import { Plus, X, Edit2, Save, Trash2 } from 'lucide-react';
import { db } from '../../firebase';
import './OrderSidebar.css';

const EnhancedOrderSidebar = ({
  showSidebar,
  setShowSidebar,
  sidebarType,
  orderDetails,
  handleOrderDetailsChange,
  salesOrder,
  purchaseOrder,
  items,
  removeFromOrder,
  createOrder,
  formatPrice,
  companyId,
  onUpdateOrder,
}) => {
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [newProduct, setNewProduct] = useState({
    id: '',
    name: '',
    quantity: 1,
    price: 0
  });
  
  const dropdownRef = useRef(null);
  const order = sidebarType === 'sales' ? salesOrder : purchaseOrder;

  useEffect(() => {
    fetchContacts();
    fetchAvailableProducts();
  }, [companyId, sidebarType]);

  const fetchContacts = async () => {
    if (!companyId) {
      setError('Company ID is required');
      return;
    }

    try {
      const contactsRef = collection(db, 'contacts');
      const q = query(
        contactsRef,
        where('companyId', '==', companyId),
        where(sidebarType === 'sales' ? 'isCustomer' : 'isSupplier', '==', true)
      );
      
      const querySnapshot = await getDocs(q);
      const contactsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setContacts(contactsList);
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setError('Error loading contacts');
    }
  };

  const handleContactSearch = (e) => {
    const searchValue = e.target.value;
    handleOrderDetailsChange({
      target: {
        name: 'customerSupplierName',
        value: searchValue
      }
    });

    const filtered = contacts.filter(contact => 
      contact.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredContacts(filtered);
    setShowDropdown(true);
  };

  const handleContactSelect = (contact) => {
    handleOrderDetailsChange({
      target: {
        name: 'customerSupplierName',
        value: contact.name
      }
    });
    setShowDropdown(false);
  };

  const fetchAvailableProducts = async () => {
    try {
      const productsRef = collection(db, 'inventory');
      const q = query(productsRef, where('companyId', '==', companyId));
      const querySnapshot = await getDocs(q);
      const productsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAvailableProducts(productsList);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Error loading products');
    }
  };

  const handleProductEdit = (product) => {
    setEditingProduct({
      ...product,
      quantity: order[product.id] || 1,
      price: sidebarType === 'sales' ? product.sell_price : product.cost_price
    });
  };

  const handleProductUpdate = (updatedProduct) => {
    const updatedOrder = {
      ...order,
      [updatedProduct.id]: updatedProduct.quantity
    };
    
    onUpdateOrder(updatedOrder, sidebarType);
    setEditingProduct(null);
  };

  const handleAddProduct = () => {
    if (!newProduct.id) return;
    
    const product = availableProducts.find(p => p.id === newProduct.id);
    const updatedOrder = {
      ...order,
      [newProduct.id]: newProduct.quantity
    };
    
    onUpdateOrder(updatedOrder, sidebarType);
    setNewProduct({ id: '', name: '', quantity: 1, price: 0 });
    setShowAddProduct(false);
  };

  const calculateTotal = () => {
    return Object.entries(order).reduce((total, [productId, quantity]) => {
      const product = items.find(item => item.id === productId);
      if (!product) return total;
      const price = sidebarType === 'sales' ? product.sell_price : product.cost_price;
      return total + (quantity * price);
    }, 0);
  };

  

  return (
    <div className={`sidebar ${showSidebar ? 'show' : ''}`}>
      <div className="sidebar-content">
    <div className={`fixed right-0 top-0 h-full w-96 bg-white shadow-xl transform transition-transform duration-200 ${showSidebar ? 'translate-x-0' : 'translate-x-full'}`}>
      <div className="p-4 h-full flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{sidebarType === 'sales' ? 'Sales Order' : 'Purchase Order'}</h2>
          <button 
            onClick={() => setShowSidebar(false)}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-4 w-4" />
          </button>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
            {error}
          </div>
        )}

        <div className="bg-white rounded-lg border p-4 mb-4">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Order ID</label>
              <input
                type="text"
                name="orderId"
                value={orderDetails.orderId}
                onChange={handleOrderDetailsChange}
                placeholder="Order ID"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Due Date</label>
              <input
                type="date"
                name="dueDate"
                value={orderDetails.dueDate}
                onChange={handleOrderDetailsChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="relative">
              <label className="block text-sm font-medium mb-1">
                {sidebarType === 'sales' ? 'Customer' : 'Supplier'}
              </label>
              <div className="autocomplete-container" ref={dropdownRef}>
            <input
              type="text"
              name="customerSupplierName"
              value={orderDetails.customerSupplierName}
              onChange={handleContactSearch}
              placeholder={sidebarType === 'sales' ? 'Customer Name' : 'Supplier Name'}
              required
            />
            {showDropdown && filteredContacts.length > 0 && (
              <div className="autocomplete-dropdown">
                {filteredContacts.map(contact => (
                  <div
                    key={contact.id}
                    className="autocomplete-item"
                    onClick={() => handleContactSelect(contact)}
                  >
                    <div className="contact-name">{contact.name}</div>
                    <div className="contact-details">
                      {contact.email && <span>{contact.email}</span>}
                      {contact.phone && <span>{contact.phone}</span>}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
            </div>
          </div>
        </div>

        <div className="flex-grow overflow-auto">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium">Products</h3>
            <button
              onClick={() => setShowAddProduct(true)}
              className="px-3 py-2 border rounded-md hover:bg-gray-50 flex items-center gap-2"
            >
              <Plus className="h-4 w-4" />
              Add Product
            </button>
          </div>

          {showAddProduct && (
            <div className="bg-white rounded-lg border p-4 mb-4">
              <div className="space-y-4">
                <select
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={newProduct.id}
                  onChange={(e) => {
                    const product = availableProducts.find(p => p.id === e.target.value);
                    setNewProduct({
                      id: e.target.value,
                      name: product?.name || '',
                      quantity: 1,
                      price: sidebarType === 'sales' ? product?.sell_price : product?.cost_price
                    });
                  }}
                >
                  <option value="">Select Product</option>
                  {availableProducts.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </select>

                <input
                  type="number"
                  min="1"
                  value={newProduct.quantity}
                  onChange={(e) => setNewProduct({
                    ...newProduct,
                    quantity: parseInt(e.target.value) || 1
                  })}
                  placeholder="Quantity"
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />

                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => setShowAddProduct(false)}
                    className="px-4 py-2 border rounded-md hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAddProduct}
                    disabled={!newProduct.id}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}

          {Object.entries(order).map(([productId, quantity]) => {
            const product = items.find(item => item.id === productId);
            if (!product) return null;

            return (
              <div key={productId} className="bg-white rounded-lg border p-4 mb-4">
                {editingProduct?.id === productId ? (
                  <div className="space-y-4">
                    <input
                      type="number"
                      min="1"
                      value={editingProduct.quantity}
                      onChange={(e) => setEditingProduct({
                        ...editingProduct,
                        quantity: parseInt(e.target.value) || 1
                      })}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={() => setEditingProduct(null)}
                        className="px-4 py-2 border rounded-md hover:bg-gray-50"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleProductUpdate(editingProduct)}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-between items-center">
                    <div>
                      <h4 className="font-medium">{product.name}</h4>
                      <p className="text-sm text-gray-600">
                        Quantity: {quantity} × ${formatPrice(sidebarType === 'sales' ? product.sell_price : product.cost_price)}
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleProductEdit(product)}
                        className="p-2 hover:bg-gray-100 rounded-full"
                      >
                        <Edit2 className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => removeFromOrder(productId, sidebarType)}
                        className="p-2 hover:bg-gray-100 rounded-full"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="mt-auto">
          <div className="flex justify-between items-center py-4 border-t">
            <span className="font-medium">Total:</span>
            <span className="text-lg font-bold">${formatPrice(calculateTotal())}</span>
          </div>

          <button
            onClick={createOrder}
            disabled={isLoading || Object.keys(order).length === 0}
            className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Create {sidebarType === 'sales' ? 'Sales' : 'Purchase'} Order
          </button>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default EnhancedOrderSidebar;