import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  where,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import getCompanyIdForUser from "../utils/getCompanyIdForUser";
import { getLocales } from "react-native-localize";
import { createXeroSalesOrder } from "../utils/xeroAccounts";

function SalesOrders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingOrder, setEditingOrder] = useState(null);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const { currentUser } = useAuth();
  const [companyId, setCompanyId] = useState(null);
  const [userLocale, setUserLocale] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState("not-completed");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (currentUser) {
      fetchCompanyId();
    }
    const locales = getLocales();
    setUserLocale(locales[0]);
  }, [currentUser]);

  useEffect(() => {
    if (companyId) {
      fetchOrders();
    }
  }, [companyId]);

  useEffect(() => {
    filterOrders();
  }, [orders, statusFilter, startDate, endDate]);

  const fetchCompanyId = async () => {
    const id = await getCompanyIdForUser(currentUser.uid);
    if (id) {
      setCompanyId(id);
    } else {
      setError("Failed to fetch company ID. Please try again.");
    }
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const q = query(
        collection(db, "salesOrders"),
        where("companyId", "==", companyId)
      );
      const querySnapshot = await getDocs(q);
      const fetchedOrders = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrders(fetchedOrders);
    } catch (err) {
      console.error("Error fetching orders: ", err);
      setError("Failed to fetch sales orders. Please try again.");
    }
    setLoading(false);
  };

  const handleEdit = (order) => {
    setEditingOrder(order);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "salesOrders", editingOrder.id), editingOrder);
      setEditingOrder(null);
      fetchOrders();
    } catch (err) {
      console.error("Error updating order: ", err);
      setError("Failed to update order. Please try again.");
    }
  };

  const sendToXero = async (order) => {
    try {
      // Format the order data according to Xero's API requirements
      const xeroOrderData = {
        Type: "ACCREC",
        Contact: {
          Name: order.customerName,
        },
        LineItems: order.items.map((item) => ({
          Description: item.name,
          Quantity: item.quantity,
          UnitAmount: item.price,
          AccountCode: "200", // Replace with appropriate account code
        })),
        Date: new Date().toISOString().split("T")[0],
        DueDate: order.dueDate,
        Reference: order.orderId,
      };

      const xeroResponse = await createXeroSalesOrder(xeroOrderData);
      console.log("Order sent to Xero:", xeroResponse);

      // Update the order status in Firestore
      await updateDoc(doc(db, "salesOrders", order.id), {
        xeroSent: true,
        xeroInvoiceId: xeroResponse.Invoices.at(0).InvoiceID,
      });

      // Refresh the orders list
      fetchOrders();
    } catch (error) {
      console.error("Error sending order to Xero:", error);
      setError("Failed to send order to Xero. Please try again.");
    }
  };

  const toggleOrderDetails = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  const formatDate = (timestamp) => {
    const dateCurrent = Date.parse(timestamp);
    const dateStringify = new Date(dateCurrent).toLocaleDateString("en-GB");
    const timeStringify = new Date(dateCurrent).toLocaleTimeString("en-GB");
    return String(dateStringify + " " + timeStringify);
  };

  const filterOrders = () => {
    let filtered = orders;

    // Status filter
    if (statusFilter !== "all") {
      filtered =
        statusFilter === "not-completed"
          ? filtered.filter((order) => order.status !== "completed")
          : filtered.filter((order) => order.status === statusFilter);
    }

    // Date range filter
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59); // Set to end of day

      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.createdAt);
        return orderDate >= start && orderDate <= end;
      });
    }

    setFilteredOrders(filtered);
  };

  const calculateDaysDifference = (date1, date2) => {
    if (!date1 || !date2) return "N/A";
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diffTime = Math.abs(d2 - d1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  if (loading) return <div>Loading sales orders...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="sales-orders-container">
      <h1>Sales Orders</h1>
      <div className="filter-container">
        <label htmlFor="status-filter">Filter by status: </label>
        <select
          id="status-filter"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="not-completed">Not Completed</option>
          <option value="all">All</option>
          <option value="pending">Pending</option>
          <option value="ordered">Ordered</option>
          <option value="received">Received</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>

        <div className="date-filter">
          <label htmlFor="start-date">Start Date: </label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label htmlFor="end-date">End Date: </label>
          <input
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <table className="sales-orders-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Customer Name</th>
            <th>Date Placed</th>
            <th>Due Date</th>
            <th>Days Since Placed</th>
            <th>Days Until Due</th>
            <th>Total Amount</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => {
            const today = new Date();
            const daysSincePlaced = calculateDaysDifference(
              order.createdAt,
              today
            );
            const daysUntilDue = calculateDaysDifference(today, order.dueDate);
            return (
              <React.Fragment key={order.id}>
                <tr>
                  <td>{order.orderId}</td>
                  <td>{order.customerName}</td>
                  <td>{formatDate(order.createdAt)}</td>
                  <td>{formatDate(order.dueDate)}</td>
                  <td>{daysSincePlaced}</td>
                  <td>{daysUntilDue}</td>
                  <td>${order.totalAmount}</td>
                  <td>{order.status}</td>
                  <td>
                    <button onClick={() => toggleOrderDetails(order.id)}>
                      {expandedOrderId === order.id
                        ? "Hide Details"
                        : "Show Details"}
                    </button>
                    <button onClick={() => handleEdit(order)}>Edit</button>
                    {!order.xeroSent && (
                      <button onClick={() => sendToXero(order)}>
                        Send to Xero
                      </button>
                    )}
                  </td>
                </tr>
                {expandedOrderId === order.id && (
                  <tr>
                    <td colSpan="9">
                      <div className="order-details">
                        <h3>
                          {order.orderId} : {order.customerName}
                        </h3>
                        <p>Status: {order.status}</p>
                        <p>Created At: {order.createdAt}</p>
                        <p>Due Date: {order.dueDate}</p>
                        <table className="items-table">
                          <thead>
                            <tr>
                              <th>Item Name</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.items.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.quantity}</td>
                                <td>${item.price}</td>
                                <td>
                                  ${(item.quantity * item.price).toFixed(2)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      {editingOrder && (
        <div className="edit-order-modal">
          <form onSubmit={handleEditSubmit}>
            <h2>Edit Order</h2>
            <label>
              Customer Name:
              <input
                type="text"
                value={editingOrder.customerName}
                onChange={(e) =>
                  setEditingOrder({
                    ...editingOrder,
                    customerName: e.target.value,
                  })
                }
              />
            </label>
            <label>
              Total Amount:
              <input
                type="number"
                value={editingOrder.totalAmount}
                onChange={(e) =>
                  setEditingOrder({
                    ...editingOrder,
                    totalAmount: parseFloat(e.target.value),
                  })
                }
              />
            </label>
            <label>
              Status:
              <select
                value={editingOrder.status}
                onChange={(e) =>
                  setEditingOrder({ ...editingOrder, status: e.target.value })
                }
              >
                <option value="pending">Pending</option>
                <option value="processing">Processing</option>
                <option value="shipped">Shipped</option>
                <option value="delivered">Delivered</option>
              </select>
            </label>
            <button type="submit">Save Changes</button>
            <button type="button" onClick={() => setEditingOrder(null)}>
              Cancel
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default SalesOrders;
