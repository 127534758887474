import React, { useState , useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SidebarProvider, useSidebar } from './contexts/SidebarContext';
import SidebarMenu from './components/SidebarMenu';
import Footer from './components/Footer';
import Home from './components/Home';
import Inventory from './components/Inventory';
import SalesOrders from './components/SalesOrders';
import PurchaseOrders from './components/PurchaseOrders';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Welcome from './components/Welcome';
import Contacts from './components/XeroContacts';
import ValidateUsers from './components/ValidateUsers';
import CompanySwitcher from './components/CompanySwitcher';
import XeroCallback from './components/XeroCallback';
import MyAccount from './components/MyAccount';
import Payment from './components/Payment';
import Header from './components/layout/Header';
import { setupNotificationListeners } from './/services/notificationService';
import { getApp } from "@firebase/app";
import { getStripePayments } from "@invertase/firestore-stripe-payments";


import { getLocales } from "react-native-localize";

function PrivateRoute({ children, adminOnly = false }) {
  const { currentUser, userRole } = useAuth();
  if (!currentUser) return <Navigate to="/welcome" />;
  if (adminOnly && userRole !== 'admin') return <Navigate to="/signin" />;
  return children;
}

function MainContent({ children, isAuthenticated }) {
  const sidebarContext = useSidebar();
  const isExpanded = sidebarContext ? sidebarContext.isExpanded : false;
  
  let mainContentClass = 'main-content';
  if (isAuthenticated) {
    mainContentClass += isExpanded ? ' expanded' : ' collapsed';
  } else {
    mainContentClass += ' full-width';
  }
  
  return <main className={mainContentClass}>{children}</main>;
}

function AuthenticatedContent({ children }) {
  return (
      <div className="app-container">
        {children}
      </div>
  );
}

function AppContent() {
  const { currentUser, loading, userRole } = useAuth();
  const [currentCompany, setCurrentCompany] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    let cleanupListeners;

    if (currentUser?.companyId) {
      // Setup notification listeners
      cleanupListeners = setupNotificationListeners(
        currentUser.companyId,
        currentUser.uid
      );
    }

    // Cleanup on unmount or when user/companyId changes
    return () => {
      if (cleanupListeners) {
        cleanupListeners();
      }
    };
  }, [currentUser]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleCompanyChange = (companyId) => {
    setCurrentCompany(companyId);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const content = (
    <Routes>
      <Route path="/welcome" element={!currentUser ? <Welcome /> : <Navigate to="/" />} />
      <Route path="/signin" element={!currentUser ? <SignIn /> : <Navigate to="/" />} />
      <Route path="/signup" element={!currentUser ? <SignUp /> : <Navigate to="/" />} />
      <Route path="/" element={<PrivateRoute><Home currentCompany={currentCompany} /></PrivateRoute>} />
      <Route path="/inventory" element={<PrivateRoute><Inventory currentCompany={currentCompany} /></PrivateRoute>} />
      <Route path="/sales-orders" element={<PrivateRoute><SalesOrders currentCompany={currentCompany} /></PrivateRoute>} />
      <Route path="/purchase-orders" element={<PrivateRoute><PurchaseOrders currentCompany={currentCompany} /></PrivateRoute>} />
      <Route path="/contacts" element={<PrivateRoute><Contacts /></PrivateRoute>} />
      <Route path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
      <Route path="/validate-users" element={<PrivateRoute adminOnly={true}><ValidateUsers /></PrivateRoute>} />
      <Route path="*" element={<Navigate to={currentUser ? "/" : "/welcome"} />} />
      <Route path="/xero-callback" element={<XeroCallback />} />
      <Route path="/payment" element={<Payment />} />
    </Routes>
  );

  return (
    <>
      {currentUser ? (
        <AuthenticatedContent>
          <MainContent isAuthenticated={true}>
            <Header toggleSidebar={toggleSidebar} />
              {content}
            <CompanySwitcher onCompanyChange={handleCompanyChange} />
          </MainContent>
        </AuthenticatedContent>
      ) : (
        <MainContent isAuthenticated={false}>
          {content}
        </MainContent>
      )}
      <Footer />
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;